import secondaryComponents from "..";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import styles from "./CompositeForm.module.scss";
import CompositeItems from "./CompositeItems";
import CompositeDetailsForm from "./CompositeDetailsForm";
import useCompositeForm from "./useCompositeForm";
import BaseProductError from "./BaseProductError";
import primaryComponents from "../../primaryComponents";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CompositeForm = ({
  createCompositeProduct,
  compositeDetails,
  isEditMode = false,
  isSubmiting,
  isUploadingImage,
  setIsErrors,
  isErrors,
  isReplicatingProduct,
  productToReplicate,
  replicateProductIntoStores,
}) => {
  const compositeProperties = useCompositeForm({
    createCompositeProduct,
    compositeDetails,
    isEditMode,
  });
  const { currentStep, selectedProducts, setSelectedProducts, setCurrentStep } =
    compositeProperties;
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`${globalStyles.cardDefault} ${styles.compositeForm__content}`}
      >
        <div
          className="d-flex align-items-center flex-wrap"
          style={{ gap: "10px" }}
        >
          <primaryComponents.Button
            classNames={"btn btn--outline"}
            onClick={() => {
              if (currentStep === 1) setCurrentStep(0);
              else navigate(-1);
            }}
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            <span>Back</span>
          </primaryComponents.Button>
          <h4 className="mb-0">
            {isEditMode ? "Edit" : "Create"} Composite Product
          </h4>
        </div>

        <div
          className={`white ${globalStyles.cardWithBackground} ${globalStyles.pageColumnGaps}`}
        >
          <div className={styles.compositeForm__stepperCover}>
            <secondaryComponents.FormStepper
              currentStep={currentStep}
              naviagetToStep={(val) => setCurrentStep(val)}
              steps={[
                {
                  id: 0,
                  title: "Base Products",
                  description:
                    "Add the base products that make up your composite product",
                },
                {
                  id: 1,
                  title: "Composite Details",
                  description:
                    "Enter details of your composite product. Select the store it is going to be available in and enter your desired pricing.",
                },
              ]}
            />
          </div>
          {currentStep === 0 ? (
            <CompositeItems
              setCurrentStep={setCurrentStep}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          ) : (
            <CompositeDetailsForm
              compositeProperties={{
                ...compositeProperties,
                isEditMode,
                isSubmiting,
                isUploadingImage,
              }}
            />
          )}
        </div>
      </div>

      <secondaryComponents.Modal
        isActive={isErrors?.isBaseProductError}
        width={600}
        closeModal={() =>
          setIsErrors((prev) => ({ ...prev, isBaseProductError: false }))
        }
      >
        <BaseProductError
          productToReplicate={productToReplicate}
          replicateProductIntoStores={replicateProductIntoStores}
          isReplicatingProduct={isReplicatingProduct}
          closeModal={() =>
            setIsErrors((prev) => ({ ...prev, isBaseProductError: false }))
          }
        />
      </secondaryComponents.Modal>
    </>
  );
};
export default CompositeForm;
