import { useContext, useState } from "react";
import {
  useCreateCompositeProducts,
  useEditCompositeProduct,
  useReplicateProduct,
} from "../../../../../hooks/api/mutations/useInventory";
import { RequestLoader, ToastContext } from "../../../../../hooks/context";
import { useNavigate, useParams } from "react-router-dom";
import { getRandomIntInclusive } from "../../../../../helpers";
import { useGetCompositeProduct } from "../../../../../hooks/api/queries";
import { useQueryClient } from "react-query";
import { useMultipleImageUpload } from "../../SingleProducts/useMultipeImageUpload";

const useCompositeProducts = ({ isEnabled = true }) => {
  const params = useParams();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const [productToReplicate, setProductToReplicate] = useState([]);
  const [isErrors, setIsErrors] = useState({
    isBaseProductError: false,
  });
  const queryClient = useQueryClient();

  const { data: compositeDetails, isLoading: isFetchingCompositeDetails } =
    useGetCompositeProduct({
      id: params.id,
      isEnabled,
    });

  const { handleUploadMultipleMediaFiles, isUploadingImage } =
    useMultipleImageUpload();

  // create composite product function
  const compositeProduct = useCreateCompositeProducts();
  const createCompositeProduct = (data) => {
    const { media_files, ...otherSubmissionData } = data;
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    compositeProduct.mutate(otherSubmissionData, {
      onSuccess: async (data) => {
        await handleUploadMultipleMediaFiles({
          media_files: media_files,
          productId: data?.data?.data?.id,
          item_type: "composite_product",
        });
        triggerToast("Composite Product Creation Sucessful", "success");
        onSuccess();
      },
      onError: (error) => {
        if (error?.request?.status === 422) {
          const productDict = JSON.parse(error?.request?.response);
          if (productDict?.reasonCode === "missing_base_products")
            onStoreError(productDict?.errors);
        }
      },
      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  // edit composite product function
  const compositeProductEdit = useEditCompositeProduct();
  const editCompositeProduct = (data) => {
    const { media_files, ...otherSubmissionData } = data;
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    compositeProductEdit.mutate(
      { compositeDetails: otherSubmissionData, id: params.id },
      {
        onSuccess: async (data) => {
          await handleUploadMultipleMediaFiles({
            media_files: media_files,
            productId: data?.data?.data?.id,
            item_type: "composite_product",
          });
          triggerToast("Composite Product Update Sucessful", "success");
          onSuccess();
        },
        onError: (error) => {
          if (error?.request?.status === 422) {
            const productDict = JSON.parse(error?.request?.response);
            if (productDict?.reasonCode === "missing_base_products")
              onStoreError(productDict?.errors);
          }
        },
        onSettled: setRequestLoaderProgress(100),
      },
    );
  };

  const replicateProduct = useReplicateProduct();
  const replicateProductIntoStores = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    replicateProduct.mutate(
      { data: productToReplicate },
      {
        onSuccess: () => {
          triggerToast("Product replicated Sucessfully", "success");
          setProductToReplicate([]);
          setIsErrors((prev) => ({ ...prev, isBaseProductError: false }));
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const onStoreError = (productDict) => {
    const newReplicatants = [];
    for (const key in productDict) {
      newReplicatants.push({
        ...productDict[key],
        stores: productDict[key]?.store_ids,
      });
      delete newReplicatants?.[key]?.store_ids;
    }

    setProductToReplicate(newReplicatants);
    setIsErrors((prev) => ({ ...prev, isBaseProductError: true }));
  };

  const onSuccess = () => {
    queryClient.invalidateQueries(["getProductList"]);
    queryClient.invalidateQueries(["getCompositeProduct"]);
    navigate(-1);
  };

  return {
    isSubmiting: compositeProduct?.isLoading,
    isErrors,
    isUploadingImage,
    productToReplicate,
    isFetchingCompositeDetails,
    compositeDetails: compositeDetails?.data || {},
    isUpdatingComposite: compositeProduct?.isLoading,
    isReplicatingProduct: replicateProduct?.isLoading,
    editCompositeProduct,
    setIsErrors,
    createCompositeProduct,
    replicateProductIntoStores,
  };
};
export default useCompositeProducts;
