import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import useCompositeProducts from "./useCompositeProducts";

const CreateCompositeProducts = () => {
  const {
    isSubmiting,
    isErrors,
    productToReplicate,
    isReplicatingProduct,
    isUploadingImage,
    setIsErrors,
    createCompositeProduct,
    replicateProductIntoStores,
  } = useCompositeProducts({ isEnabled: false });

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Composite Products"
      description="Create composite products"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`${globalStyles.pagePaddings} ${globalStyles.pageColumnGaps}`}
        >
          <secondaryComponents.CompositeForm
            createCompositeProduct={createCompositeProduct}
            isSubmiting={isSubmiting}
            isErrors={isErrors}
            isUploadingImage={isUploadingImage}
            setIsErrors={setIsErrors}
            replicateProductIntoStores={replicateProductIntoStores}
            isReplicatingProduct={isReplicatingProduct}
            productToReplicate={productToReplicate}
          />
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default CreateCompositeProducts;
